<template>
  <div
    ref="scrollContainer"
    class="gallerypic"
    :style="[
      isInView && store.state.isPoliteLoad
        ? { backgroundImage: setBackGroundPath(imgpath) }
        : {},
      { backgroundPosition: imgposition },
    ]"
    alt=""
  >
    <!-- <ComingSoon /> -->
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useStore } from "vuex";
import { ref, onMounted } from "vue";
import getPathFunction from "@/utilities/composition/getPathFunction";
// import ComingSoon from "@/components/ui/comingsoon/ComingSoon.vue";

export default {
  // components: { ComingSoon },
  props: { imgpath: String, imgposition: String, motion: String, animation:String },
  setup(props) {
    const isInView = ref(false);
    const store = useStore();
    const allowScroll = ref(true);

    const scrollContainer = ref(null);
    const startAnimate = ref(false);

    const allowAnimation = ref(props.animation);


    const { setBackGroundPath } = getPathFunction();

    let scrollT = null;

    let tl = gsap.timeline({
      // yes, we can add it to an entire timeline!
    });

    function loadImage() {
      isInView.value = true;
    }
    function startAnimation() {
      let el = scrollContainer.value;
      // gsap.from('#circle_line_1', { duration: 1,scale: 0.8,  drawSVG: "0%",delay: 0.3 });
      // let els_2 = scrollContainer.value.querySelectorAll(".lba__path--2");
      // let time = { time: 0 };
      tl.set([el], {
        force3D: true,
        transformPerspective: 100,
        transformStyle: "preserve-3d",
      });

      tl.from(el, { duration: 2, ease: "power4.out", y: 50, opacity: 0 });

      scrollT = ScrollTrigger.create({
        // scroller: "#main__content",
        pin: false, // pin the trigger element while active
        animation: tl,
        trigger: scrollContainer.value,
        scrub: false,
        start: "top 100%",
        // end: "top 50%",
        onUpdate: (self) => {
          if (self.progress.toFixed(3) >= 0) {
            loadImage();
          }
          if (self.progress.toFixed(3) > 0.5) {
            startAnimate.value = true;
          } else {
            startAnimate.value = false;
          }
        },
      });
      scrollT.refresh();
    }

    onMounted(() => {
      if (allowScroll.value) {

        if (allowAnimation.value !== 'no-animation') {
          startAnimation();
        }
        else {
          loadImage();
        }
       
      }
    });

    return {
      setBackGroundPath,
      scrollContainer,
      startAnimate,
      store,
      isInView,
      allowAnimation
    };
  },
};
</script>
