<template>
  <div class="contactblock">
    <div class="contactblock__wrapper">
      <div class="contactblock__content">
        <!-- <div class="gallery__item"><AppHl>Kontakt</AppHl></div> -->
        <div class="introb1__content ">
        <h1 class="introb1__h1 h1 h1--white">KONTAKT</h1>
        <p class="p introb1__p p--white">
          Autotechnik Gabriel befindet sich im Mühlweg 2, in Wangen im Allgäu, Deutschland. Die Werkstatt liegt in einem Gewerbegebiet im Osten der Stadt, in der Nähe des Autobahnzubringers zur A96. </p>

      </div>

        <div class="block__text block__text--2">
          <a :href="getMail" class=""> {{ kontakt.email }}</a
          ><br />
          <a :href="getPhone" class="">{{ kontakt.phone }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import contactInformation from "@/utilities/siteinformation/contactInformation";
// import AppHl from "@/components/ui/buttons/AppHl.vue";

export default {
  components: {},
  setup() {
    const kontakt = contactInformation;
    const getMail = "mailto:" + kontakt.email;
    const getPhone = "tel:" + kontakt.phone;

    return { kontakt, getMail, getPhone };
  },
};
</script>

<style lang="scss">
.contactblock__wrapper {
  position: relative;
  width: 100%;
}
</style>
